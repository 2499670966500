import React, { useEffect, useState } from "react"
import { Navbar, Nav } from "react-bootstrap";
import Clock from "../images/clock.svg";
import Facebook from "../images/facebook.svg";
import Linkedin from "../images/linkedin.svg";
import Youtube from "../images/youtube.svg";
import Frenchflag from "../images/french_flag.svg";
import DutchFlag from '../images/dutch_flag.svg';//must be replaced
import home from '../images/home.svg';
import logout from '../images/logout.svg'
import {
  AUTH_TOKEN_KEY,
  getDateFormated,
  KEY_LANGUAGE_USER,
  NL,
  FR,
  URL_IS_AUTH,
  translate,
  getUserLanguage, LINK_FACEBOOK
} from "../utils"
import axios from "axios"

const PreHeader = () => {
  const [isAuth, setisAuth] = useState(false);
  const [flag,setFlag] = useState(Frenchflag)
  const [lang,setlang] = useState(NL)

  useEffect( () => {
    const language = getUserLanguage()
    if (language) setlang(language)
        if(localStorage.getItem(AUTH_TOKEN_KEY))
              auth();
  },[])

  const auth = () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const url = URL_IS_AUTH;
    axios.get(url,data)
      .then(res => {
        if (res.data) {
          setisAuth(true)
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data);
        }
        console.log(err)
        setisAuth(false)
      });
  }

  const pressLogout = () => {
   localStorage.removeItem(AUTH_TOKEN_KEY);
    window.location.href = '/';
  }

  const changeLanguage = () => {
    if (lang === NL) {
      setlang(FR)
      localStorage.setItem(KEY_LANGUAGE_USER,FR);
    }else if (lang === FR) {
      setlang(NL)
      localStorage.setItem(KEY_LANGUAGE_USER,NL);
    }

    window.location.reload();
  }

  return (
    <div className="pre_header"  fixed="top" >
      <Navbar>
        <div className="active_user_time">
          <img
            style={{marginRight: '5px'}}
            width={16}
            height={16}
            className="img-fluid"
            src={Clock}
            alt="Clock"
          />{" "}
          <div style={{paddingRight: '15px'}}>{`${getDateFormated()}`}</div>
          <a
            href={(isAuth)?'/feed':'/'}
            style={{
            borderLeft: '1px solid white',
            paddingLeft: '15px',
            cursor: 'pointer',
          }}>
            <img
              style={{transform: 'translateY(-2px)'}}
              width={16}
              height={16}
              className="img-fluid"
              src={home}
              alt="Home"
            />
          </a>
        </div>
        {(!isAuth)?
        <div className="text-right welcomeBtnWrap">
          <a href={'/login'} className="btn btn-dark welcomeBtn" to="/login">
            _Log in
          </a>
        </div>
        :null
        }
        <Navbar>
          <Nav className="ml-auto">
            <Nav.Link target={'__blank'} href={LINK_FACEBOOK}>
              <img
                width={17}
                height={17}
                className="img-fluid"
                src={Facebook}
                alt="Facebook"
              />
            </Nav.Link>
            <Nav.Link target={'__blank'} href="https://www.linkedin.com/company/enter-business/">
              <img
                width={17}
                height={17}
                className="img-fluid"
                src={Linkedin}
                alt="Linkedin"
              />
            </Nav.Link>
            <Nav.Link target={'__blank'} href="https://www.youtube.com/channel/UCt4seV_4CHUDemaLKtfQ8Ng">
              <img
                width={21}
                height={17}
                className="img-fluid"
                src={Youtube}
                alt="Youtube"
              />
            </Nav.Link>
            <Nav.Link href="#">
              <img
                onClick={() => changeLanguage()}
                width={25}
                height={17}
                className="img-fluid"
                src={(lang===FR)?DutchFlag:Frenchflag}
                alt="flag"
              />
            </Nav.Link>
            {(isAuth)?
              <Nav.Link onClick={() => pressLogout()} href="#" style={{color: 'white',fontSize: '12px'}}>
                <img
                  width={17}
                  className="img-fluid"
                  src={logout}
                  alt="Logout"
                /> _ {translate('afmelden')}
              </Nav.Link>:null
            }

          </Nav>
        </Navbar>
      </Navbar>
    </div>
  );
};

export default PreHeader;
